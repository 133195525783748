import {Injectable} from '@angular/core';
import {APIService, TaskProposalCreateSerializer, TaskProposalSerializer, VisitViewSetQueryParams} from './api.service';
import {UserAuthService} from './user-auth.service';
import {combineLatest, forkJoin, Observable, of, ReplaySubject, Subject, throwError} from 'rxjs';
import {catchError, map, share, switchMap, take, tap} from 'rxjs/operators';
import {User} from '../models/user';
import {ConfirmService} from '../portal/@portal-shared/confirm/confirm.service';
import {CCMService, TaskTypeMerged} from './ccm.service';
import {Patient} from '../models/patient';
import {OverlayService} from './overlay.service';
import {Visit, VisitStatus} from '../models/visit';
import {Router} from '@angular/router';
import {PhysicianTeam} from '../models/physician-team';
import {SlidePanelService} from '../portal/slide-panel/slide-panel.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {getId} from '../utils/type.utils';
import {Task} from '../models/models';
import {TaskProposalEditInterface, TaskProposalService} from './task-proposal.service';
import {TaskProposal, TaskProposalQuery} from '../models/task-proposal';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastService} from './toast.service';
import {toDateTime} from '../utils/date.utils';
import {assignOrCreate} from '../models/model-base';
import {RepoAction} from './utils/cache-base';
import {DateTime} from 'luxon';
import {TaskTypeService} from './task-type.service';
import {PatientService} from './patient.service';
import {compareDates} from '../@theme/helpers';
import {SimpleDatePipe} from '../@theme/pipes/simple-date.pipe';
import {extractErrorMessage} from '../utils/error.utils';
import {ContentDefinitions} from '../definitions/definitions';
import {AccessorUtils, GetterCache} from '../utils/accessor.utils';

export interface VisitNavPrams {
    visit?: number;
    tab?: number;
    subTab?: number;
    proposal?: number;
}

export interface TaskProposalFilter {
    status?: VisitStatus;
    type?: string;
}

export interface TaskProposalCheck {
    warningMsg: string;
    visitProposal: TaskProposal;
}

@Injectable({
    providedIn: 'root',
})
export class VisitService {
    constructor(private api: APIService,
                private userAuth: UserAuthService,
                private confirmService: ConfirmService,
                public ccm: CCMService,
                private toastService: ToastService,
                private overlayService: OverlayService,
                private router: Router,
                private slidePanelService: SlidePanelService,
                private permissionService: NgxPermissionsService,
                private proposalService: TaskProposalService,
                private patientService: PatientService,
                private taskTypeService: TaskTypeService) {
        this.refreshVisits();
        this.taskTypeService.taskTypesMerged$.pipe(take(1)).subscribe(taskTypes => this._taskTypes = taskTypes);
        this.userAuth.user.subscribe(u => {
            if (this._user) this._reset();
            this._user = u;
            this._user$.next(u);
        });
    }

    private _user: User;
    private _user$ = new ReplaySubject<User>(1);
    private _taskTypes: TaskTypeMerged[];

    refreshVisits$ = new ReplaySubject<void>();
    updates$ = new Subject<{action: RepoAction; visit: Visit}>();

    @GetterCache()
    get physicianTeams$(): Observable<PhysicianTeam[]> {
        return this.userAuth.physicianTeams$;
    }

    @GetterCache()
    get visitTaskTypes$(): Observable<TaskTypeMerged[]> {
        return this.ccm.taskTypesMerged$.pipe(
            map(types => types.filter(x => x.is_visit || x.key === 'VISIT_FOLLOW_UP_CARE_NOTE'))
        );
    }

    @GetterCache()
    get visitTaskTypeOptionsWAcute$(): Observable<TaskTypeMerged[]> {
        return this.ccm.taskTypesMergedOptionsWAcute$.pipe(
            map(types => types.filter(x => x.is_visit || x.key === 'VISIT_FOLLOW_UP_CARE_NOTE'))
        );
    }

    getPhysicianTeam$(id: number): Observable<PhysicianTeam> {
        return this.userAuth.retrievePhysicianTeam$(id);
    }

    getVisits$(filter?: VisitViewSetQueryParams): Observable<Visit[]> {
        return Visit.viewSet.list(filter).pipe(
            switchMap(res => {
                if (!res.length) return of([]);
                return forkJoin(
                    res.map(v =>
                        this.getPhysicianTeam$(v.user_facility).pipe(
                            map(pt => assignOrCreate(Visit, v, this, pt)),
                            catchError(err => of(null)),
                        )
                    )
                );
            }),
        );
    }

    getFutureVisits$(filter = {}): Observable<Visit[]> {
        return this.getVisits$({...filter, start_date: DateTime.now().toFormat('yyyy-MM-dd')});
    }

    getVisit$(visit: Visit | number): Observable<Visit> {
        const vid = typeof visit === 'number' ? visit : visit.id;
        return Visit.viewSet.retrieve(vid).pipe(
            switchMap(v => this.getPhysicianTeam$(v.user_facility).pipe(
                map(pt => (assignOrCreate(Visit, v, this, pt)))
            ))
        );
    }

    saveVisit(args: {id?: number; date: Date | string; physicianTeam?: number; user_facility?: number; physician?: number; secondary_assignees?: number[]}, showToast = true): Observable<Visit> {
        const data = {
            date: args.date instanceof Date ? toDateTime(args.date).toFormat('yyyy-MM-dd') : args.date,
            user_facility: args.user_facility || args.physicianTeam,
            physician: getId(args.physician),
            secondary_assignees: args.secondary_assignees,
        };
        if (!(data.date && data.user_facility && data.physician)) {
            this.toastService.warning(`A Date, a Care Team and a Physician has to be provided when creating a ${ContentDefinitions.VISIT.name.capitalized}`);
            return null;
        }
        // TODO: fix serializer errors
        const obs$ = (args.id ? this.api.VisitViewSet.partial_update(args.id, data) : this.api.VisitViewSet.create(data)).pipe(
            switchMap(v => this.getPhysicianTeam$(v.user_facility).pipe(
                map(pt => {
                    const visit = assignOrCreate(Visit, v, this, pt);
                    this.proposalService.invalidate();
                    if (showToast) {
                        this.toastService.buttonToast(
                            'success',
                            () => this.openCreateVisit({
                                team: args.physicianTeam,
                                user: args.physician,
                            }),
                            `${ContentDefinitions.VISIT.name.capitalized} has been successfully ${args.id ? 'saved' : 'created'}`,
                            'Create Another',
                            `${ContentDefinitions.VISIT.name.capitalized} ${args.id ? 'Saved' : 'Created'}`,
                        );
                    }
                    this.refreshVisits();
                    this.updates$.next({action: args.id ? 'update' : 'add', visit});
                    return visit;
                }, err => this._throwError(err, `An error occurred while trying to save ${ContentDefinitions.VISIT.name.capitalized}`, showToast)),
            )),
            share()
        );
        obs$.subscribe();
        return obs$;
    }

    createVisit(args: {date: Date | string; physicianTeam?: number; user_facility?: number; physician?: number}): Observable<Visit> {
        return this.saveVisit(args);
    }

    removeVisit(visit: Visit) {
        const obs$ = this.confirmService.requestConfirm({
            title: `Remove ${ContentDefinitions.VISIT.name.capitalized}`,
            body: `Are you sure you want to remove this ${ContentDefinitions.VISIT.name.capitalized}? The listed patients will appear in the next ${ContentDefinitions.VISIT.name.capitalized} you start planning.`,
        }).pipe(
            switchMap(() => this.api.VisitViewSet.destroy(visit.id)),
            catchError(err => {
                this.toastService.error(extractErrorMessage(err, `remove ${ContentDefinitions.VISIT.name.capitalized}`));
                return throwError(err);
            }),
            tap(() => {
                this.toastService.success(`${ContentDefinitions.VISIT.name.capitalized} was removed successfully`);
                this.refreshVisits();
                this.updates$.next({action: 'remove', visit});
            }),
            share(),
        );
        obs$.subscribe();
        return obs$;
    }

    openTask(task: Task | number) {
        this.slidePanelService.addComponent('TASK', getId(task));
    }

    setSort(sortArray: number[]) {
        return this.api.TaskProposalViewSet.sort({sort: sortArray});
    }

    private _navParams: VisitNavPrams;

    getNavPrams() {
        const np = this._navParams;
        this._navParams = null;
        return np;
    }

    openPlanner(navParams?: VisitNavPrams) {
        this._navParams = navParams;
        // TODO: impelement param handling
        if (!navParams) return this.router.navigate(['/scheduler']);

        const np = {...navParams};
        delete np.visit;
        this.router.navigate([ContentDefinitions.VISIT.getPath(navParams.visit)], {queryParams: np});
    }

    openCreateVisit(partialData?: any) {
        if (!this.permissionService.getPermission('PERMISSION_WRITE_VISITS')) return;

        this.slidePanelService.addComponent('VISIT_EDIT', null, null, false, {
            componentInputs: partialData,
        });
    }

    openEditVisit(visit: Visit | number) {
        if (!this.permissionService.getPermission('PERMISSION_WRITE_VISITS')) return;

        this.slidePanelService.addComponent('VISIT_EDIT', getId(visit));
    }

    openComposingPatientList(visit: Visit | number) {
        this.slidePanelService.addComponent('VISIT_COMPOSING_PATIENT_LIST', getId(visit));
    }

    isInVisitPlan(patientId: number): Observable<boolean> {
        return new Observable<boolean>();
    }

    private _reset() {
        this.proposalService.reset();
        AccessorUtils.clearCache(this);
    }

    private _throwError(err: any, defaultMessage = 'An error occurred while trying to save data', showToast = true) {
        if (showToast) this.toastService.error(err.error && (err.error.detail || Object.keys(err.error).map(key => typeof err.error[key] === 'string' ? '' : err.error[key].join('\n')).join('\n')) || defaultMessage);
        throw new HttpErrorResponse(err);
    }

    // TaskProposalService methods
    getProposals$(args: TaskProposalQuery, forceFetch?: boolean): Observable<TaskProposal[]>;
    getProposals$(args: TaskProposalQuery, nonCacheFilterArgs?: {patient_ids: number[]}, forceFetch?: boolean): Observable<TaskProposal[]>;
    getProposals$(args: TaskProposalQuery, nonCacheFilterArgsOrForceFetch?: {patient_ids: number[]} | boolean, forceFetch?: boolean): Observable<TaskProposal[]> {
        let nonCacheFilterArgs: {patient_ids: number[]};
        if (typeof nonCacheFilterArgsOrForceFetch === 'boolean') {
            forceFetch = nonCacheFilterArgsOrForceFetch;
        } else {
            nonCacheFilterArgs = nonCacheFilterArgsOrForceFetch;
        }

        if (nonCacheFilterArgs?.patient_ids && !nonCacheFilterArgs.patient_ids.length) return of([]);

        return this.proposalService.getProposals$({...args}, nonCacheFilterArgs, forceFetch);
    }

    retrieveProposal$(id: number): Observable<TaskProposal> {
        return this.proposalService.retrieveProposal$(id);
    }

    openEditProposal(patient: Patient, taskId: number | 'new', taskProposalData: Partial<TaskProposalEditInterface> = {}) {
        this.proposalService.openEditProposal(patient, taskId, taskProposalData);
    }

    removeProposal(proposal: TaskProposal | number): Observable<boolean> {
        return this.proposalService.removeProposal(proposal);
    }

    confirmProposal(proposal: TaskProposal | number, confirm: boolean, visit?: Visit | number): Observable<TaskProposal> {
        return this.proposalService.confirmProposal(proposal, confirm, visit);
    }

    unConfirmProposal(proposal: TaskProposal | number): Observable<TaskProposal> {
        return this.proposalService.unConfirmProposal(proposal);
    }

    discardProposal(proposal: TaskProposal | number) {
        return this.proposalService.discardProposal(proposal);
    }

    saveProposal(proposal: TaskProposalSerializer | TaskProposalCreateSerializer | TaskProposal, id?: number, intent?: Subject<TaskProposal>, showToast = true): Observable<TaskProposal> {
        return this.proposalService.saveProposal(proposal, id, intent, showToast);
    }

    proposalCollisionConfirm$(checkResult: TaskProposalCheck) {
        if (Object.values(checkResult).length) {
            const tt = this._taskTypes?.find(x => x.key === checkResult.visitProposal.type);
            return this.confirmService.requestConfirm(
                {
                    body: `There is an existing ${tt.name} type proposal ${checkResult.warningMsg} (${SimpleDatePipe.transform(checkResult.visitProposal.visit.date)})`,
                    showCancelBtn: true,
                    confirmBtns: [
                        {
                            text: 'Edit existing Proposal',
                            confirmValue: 'EDIT',
                            colorClass: 'info',
                        },
                        {
                            text: 'Add Proposal anyway',
                            confirmValue: 'ADD',
                            colorClass: 'primary',
                        },
                    ],
                },
                true
            ).pipe(
                map(x => ({confirmValue: x || 'CANCEL', proposal: checkResult.visitProposal || null}))
            );
        }
        return of({confirmValue: 'ADD', proposal: null});
    }

    checkProposal$(proposal: TaskProposalCreateSerializer): Observable<TaskProposalCheck> {
        return this.patientService.getPatientDetail(getId(proposal.patient)).relatedPatients$.pipe(
            switchMap(relatedPatients => combineLatest(
                relatedPatients.map(rp => this.proposalService.getProposals$({patient: getId(rp), physician_team: proposal.physician_team})),
            )),
            take(1),
            map(rpp => ([] as TaskProposal[]).concat(...rpp)),
            map(proposals => (this._getCollidedProposal(proposals, proposal, toDateTime(Visit.get(getId(proposal.visit))?.date)))),
        );
    }

    private _getCollidedProposal(taskProposals: TaskProposal[], proposal: TaskProposalCreateSerializer, visitDate: DateTime): TaskProposalCheck {
        const checkFns = [
            {
                warningMsg: 'within one day',
                visitProposal: this._skilledProposalWithinOneDay(taskProposals, proposal, visitDate),
            },
            {
                warningMsg: 'within three days',
                visitProposal: this._visitProposalWithinThreeDays(taskProposals, proposal, visitDate),
            },
        ];
        return checkFns.find(x => x.visitProposal);
    }

    private _skilledProposalWithinOneDay(taskProposals: TaskProposal[], proposal: TaskProposalCreateSerializer, visitDate: DateTime): TaskProposal {
        return taskProposals && proposal.type === 'VISIT_SKILLED' && taskProposals.filter(tp => tp.type === 'VISIT_SKILLED' && tp.visit).find(x => Math.abs(Math.trunc(toDateTime(x.visit?.date).diff(visitDate, 'days').days)) <= 1 && x.id !== proposal.id);
    }

    private _visitProposalWithinThreeDays(taskProposals: TaskProposal[], proposal: TaskProposalCreateSerializer, visitDate: DateTime): TaskProposal {
        return taskProposals?.sort((a, b) => compareDates(a.visit?.date, b.visit?.date, true)).find(x => Math.abs(Math.trunc(toDateTime(x.visit?.date)?.diff(visitDate, 'days').days)) <= 3 && x.id !== proposal.id);
    }

    refreshVisits() {
        this.refreshVisits$.next();
    }

    isPatientPhysician(visit: Visit, physicianTeamIds: number[]): boolean {
        const vpid = visit.physicianTeam?.id;
        return vpid && physicianTeamIds.includes(vpid);
    }
}
